import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function MenJeansPage() {
  return (
    <CategoryWrapper
      seoTitle="Bukser"
      gender="herre"
      category="jeans"
    />
  )
}

export default MenJeansPage
